// this is our just created themes.scss file, make sure the path to the file is correct
@import "themes";

// framework component styles
@import "~@nebular/theme/styles/globals";

// install the framework styles
@include nb-install() {
  @include nb-theme-global();
}
/* ng-select */

@import "~@ng-select/ng-select/themes/default.theme.css";

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1) !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5rem;
  font-family: "Poppins", sans-serif;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: white;
  color: black;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5rem;
  font-family: "Poppins", sans-serif;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5rem;
  font-family: "Poppins", sans-serif;
}
.ng-dropdown-panel .ng-dropdown-header {
  border-bottom: none;
}
.ng-dropdown-panel {
  background-color: #ffffff;
  border-color: #e4e9f2;
  border-style: solid;
  border-width: 0.0625rem;
  border-radius: 0.25rem;
  box-shadow: none;
  overflow: hidden;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
}
/* ng-select end*/

.nb-input-shadow {
  box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 15%) !important;
  border-radius: 15px;
}

.material-icons {
  font-family: "Material Icons" !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

// body:not(.dark-mode-preloading) {
//   transition: all 0.3s linear;
// }

// body.dark-mode {
//   background-color: #2f3542;
//   color: #f1f2f6;
// }

// body.light-mode {
//   background-color: #f1f2f6;
//   color: #2f3542;
// }

.material-icons-outlined {
  font-family: "Material Icons Outlined" !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

//   .light-mode.bg-light {
//     background-color: #ffffff !important;
// }

.bg-light {
  background-color: #ffffff !important;
}

*,
body,
html {
  font-family: "Poppins", sans-serif;
}

.navbar span {
  color: #9e9e9e !important;
  cursor: pointer;
}

.navbar span:hover {
  color: #656565 !important;
  cursor: pointer;
}

.navbar ul li {
  margin-left: 5px;
  margin-right: 5px;
}

.dx-popup-title .dx-closebutton .dx-icon {
  box-sizing: border-box !important;
  width: 21px !important;
  height: 21px !important;
  background-position: 3px 3px !important;
  background-size: 15px 15px !important;
  padding: 3px !important;
  font-size: 15px !important;
  text-align: center !important;
  line-height: 15px !important;
  font: 17px/18px DXIcons !important;
}

.dx-popup-wrapper > .dx-overlay-content {
  border: 1px solid #ddd;
  background: #fff;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  border-radius: 25px !important;
}

.dx-popup-title {
  border-style: none !important;
}

/* ------- Card -------*/
nb-card {
  border-radius: 32px !important;
}
/* ------- Card End-------*/

/* ------- Table --------*/
shadow {
  box-shadow: 0 8px 15px 0px #cececec9;
}
.k-button {
  background-color: unset !important;
  border: unset !important;
  font-size: x-large;
}

.table-style {
  padding: 0px !important;
  overflow-y: auto;
  display: inline-block;
  box-shadow: 0px 5px 8px 0px rgb(0 0 0 / 15%) !important;
}

.table-style table thead {
  background-color: #f9f9f9;
  border-style: none !important;
  font-size: 16px;
  font-weight: 100;
}
.table-style table thead tr {
  border-top: 1px solid #d5d5d57d;
  white-space: nowrap;
}
.table-style table tbody tr {
  border-top: 1px solid #d5d5d57d;
}

.table-style table th,
.table td {
  border-style: none !important;
  cursor: pointer;
}

.table-style table tbody {
  font-size: 14px;
}

.table-style table tbody .active {
  background-color: #e7fefa;
  color: #1ec0a6;
  width: 70px;
}

.table-style table tbody .passive {
  background-color: #ffe0d9;
  color: #fd552b;
  width: 70px;
}

.table-style table tbody .outOfService {
  background-color: #c3c3c3;
  color: #191919;
  width: 70px;
}
.total {
  border: 1px solid #cecece;
  height: 50px;
  font-weight: bolder;

  display: flex;
  align-items: center;
}

.table-style table tbody {
  display: block;
  max-height: 900px;
  overflow: auto;
}



.table-style table thead,
.table-style table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed; /* even columns width , fix width of table too*/
  overflow: hidden;
}
.table-style table thead {
  // width: calc(100% - 1em); /* scrollbar is average 1em/16px width, remove it from thead width */
}
.table-style table {
  min-width: 768px !important;
}

.table-style table thead tr th {
  flex: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-style table td,
.table-style table th {
  overflow-wrap: break-word;
}
@media only screen and (max-width: 768px) {
  .table-style table {
    width: 400px;
    min-width: 1440px !important;
  }
}
/* ------- Table  End--------*/
@import "orwi-styles";
