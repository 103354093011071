$orwi-table-dark: #f9f9f9;
$orwi-color: #d5c9e796;

$orwi-select-max-width: 350px;
.orwi-tabel-dark {
    background-color: $orwi-table-dark;
}
.orwi-table-general-sum {
    background-color: $orwi-color !important;
}
.orwi-fullwidth {
    max-width: $orwi-select-max-width !important;
    width: 100% !important;
}
::ng-deep .AllOption nb-checkbox {
    display: none !important;
}
.AllButton {
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.5rem;
    padding: 0.9375rem 1rem;
    border: none;
    background-color: white;
}
.AllButton:hover {
    background-color: rgba(143, 155, 179, 0.16);
}
.clear-bg {
    background-color: #ff5722 !important;
}

/* -------- scrollbar -------- */
/* width */
::-webkit-scrollbar {
    width: 0.5125rem;
    height: 0.3125rem;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f7f9fc;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #e4e9f2;
    cursor: pointer;
    border-radius: 0.15625rem;
}

.format-dx-tabel .dx-header-row {
    background-color: $orwi-table-dark;
    font-size: 16px;
    font-weight: bold;
    color: #000000;
}

.format-dx-tabel .dx-data-row {
    border-top: 1px solid $orwi-table-dark;
    color: #000000;
    // background-color: $orwi-table-dark;
}
@media only screen and (max-width: 768px) {
    .format-dx-tabel {
        min-width: 1366px !important;
    }
}

/* -------- scrollbar end -------- */

@media (min-width: 1366px) {
  
    .md-wd {
        flex: 1;
        max-width: calc(100vw - 400px) !important;
    }
}